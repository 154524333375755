import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Services = lazy(() => import('../../container/services/servicesContainer'));
const OrderDetail = lazy(() => import('../../container/appointments/orderDetail'));
const AddService = lazy(() => import('../../container/services/addService'));
const EditService = lazy(() => import('../../container/services/editService'));

const ServiceRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={Services} />
      <Route path={`${path}/order-detail/:id`} component={OrderDetail} />
      <Route path={`${path}/add`} component={AddService} />
      <Route path={`${path}/edit`} component={EditService} />
    </Switch>
  );
};

export default ServiceRoutes;
