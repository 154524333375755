import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Dashboard = lazy(() => import('../../container/marketerDashboard'));
const Orders = lazy(() => import('../../container/marketerDashboard/overview/ecommerce/OrdersContainer'));
const Codes = lazy(() => import('../../container/marketerDashboard/marketerDiscountCodes'));
const Wallet = lazy(() => import('../../container/marketerDashboard/marketerWallet'));
const Providers = lazy(() => import('../../container/marketerDashboard/marketerProviders'));
const ProductRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={Dashboard} />
      <Route path={`${path}/orders`} component={Orders} />
      <Route path={`${path}/codes`} component={Codes} />
      <Route path={`${path}/wallet`} component={Wallet} />
      <Route path={`${path}/providers`} component={Providers} />
    </Switch>
  );
};

export default ProductRoutes;
