import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const JamelahOrders = lazy(() => import('../../container/jamelahOrders/ordersContainer'));
const OrderDetail = lazy(() => import('../../container/jamelahOrders/orderDetail'));
const AppointmentRoutes = () => {
  const { path } = useRouteMatch();
  // console.log('incomingPath',path)

  return (
    <Switch>
      <Route exact path={path} component={JamelahOrders} />
      <Route path={`${path}/order-detail/:id`} component={OrderDetail} />
    </Switch>
  );
};

export default AppointmentRoutes;
