import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const WorkingHours = lazy(() => import('../../container/workingHours/index'));
const EditWorkingHours = lazy(() => import('../../container/workingHours/edit'));

const ProductRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={WorkingHours} />
      {/* <Route path={`${path}/:id`} component={ProductDetail} /> */}
      <Route path={`${path}/edit`} component={EditWorkingHours} />


    </Switch>
  );
};

export default ProductRoutes;
