import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Inventories = lazy(() => import('../../container/inventory/inventories'));
const OrderDetail = lazy(() => import('../../container/inventory/orderDetail'));
const AddSupplier = lazy(() => import('../../container/inventory/addSupplier'));
const EditSupplier = lazy(() => import('../../container/inventory/editSupplier'));
const EditService = lazy(() => import('../../container/appointmentServices/editService'));
const AddOrder = lazy(()=> import('../../container/inventory/addOrder'))
const EditOrder = lazy(()=> import('../../container/inventory/editOrder'))

const ServiceRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={Inventories} />
      <Route path={`${path}/order-detail/:id`} component={OrderDetail} />
      <Route path={`${path}/add-supplier`} component={AddSupplier} />
      <Route path={`${path}/edit-supplier`} component={EditSupplier} />
      <Route path={`${path}/edit`} component={EditService} />
      <Route path={`${path}/edit-order/:id`} component={EditOrder} />
      <Route path={`${path}/add-order`} component={AddOrder} />
    </Switch>
  );
};

export default ServiceRoutes;
