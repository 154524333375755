import React, { Suspense, lazy } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Invoice = lazy(() => import('../../container/invoice'));
const GiftInvoice = lazy(() => import('../../container/invoice/GiftInvoice'));
const CommissionInvoice = lazy(() => import('../../container/invoice/CommissionInvoice'));
const Admin = () => {
  const { path } = useRouteMatch();
  console.log('path', path);
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={`${path}/order/:id`} component={Invoice} />
        <Route path={`${path}/gift/:id`} component={GiftInvoice} />
        <Route path={`${path}/commission/:id`} component={CommissionInvoice} />
        {/* <Route path={`${path}/orders`} component={JamelahOrders} /> */}
      </Suspense>
    </Switch>
  );
};

export default Admin;
