import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Settings = lazy(() => import('../../container/settings/index'));
const EditSettings= lazy(() => import('../../container/settings/editSettings'));

const SettingsRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={Settings} />
      <Route path={`${path}/edit`} component={EditSettings} />
    </Switch>
  );
};

export default SettingsRoutes;
