import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Employees = lazy(() => import('../../container/employees/employeeContainer'));
const AddEmployee = lazy(() => import('../../container/employees/addEmployee'));
const EditEmployee = lazy(() => import('../../container/employees/editEmployee'));
const EmployeeWallet = lazy(() => import('../../container/employees/employeeWalletForProvider'));
const ProductRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={Employees} />
      <Route path={`${path}/add`} component={AddEmployee} />
      <Route path={`${path}/edit/:id`} component={EditEmployee} />
      <Route path={`${path}/employee-wallet/:id`} component={EmployeeWallet} />
    </Switch>
  );
};

export default ProductRoutes;
