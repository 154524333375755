import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Products = lazy(() => import('../../container/products/productsContainer'));
const ProductDetail = lazy(() => import('../../container/products/productDetail'));
const AddProduct = lazy(() => import('../../container/products/addProduct'));
const EditProduct = lazy(() => import('../../container/products/editProduct'));

const ProductRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={Products} />
      <Route path={`${path}/detail/:id`} component={ProductDetail} />
      <Route path={`${path}/add`} component={AddProduct} />
      <Route path={`${path}/edit/:id`} component={EditProduct} />


    </Switch>
  );
};

export default ProductRoutes;
