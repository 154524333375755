export const API_KEY = 'API_KEY=fHUuioTyELUantbGA7O4qP7NkY6JMdE8';
export const ENGLISH = 'english';
export const ARABIC = 'arabic';

export const JAMELAH = 'Jamelah';
export const SERVICE = 'service';
export const PRODUCT = 'product';

export const BOTH = 'both';

export const PROMOTIONAL = 'promotional';

export const CURRENCY = 'SAR';

export const MARKETER = 'Marketer';
export const ARTIST = 'Artist';
export const ARTIST_EMPLOYEE = 'artistEmployee';
export const DRIVER = 'Driver';
export const DOCTOR = 'Doctor';
export const CO_ORDINATOR = 'Co-ordinator';
export const USER = 'User';

export const CHAT_MESSAGE_TYPE_TEXT = 'Text';
export const CHAT_MESSAGE_TYPE_IMAGE = 'Image';
export const CHAT_MESSAGE_TYPE_LOCATION = 'Location';

// request status
export const WAITING_ARTIST_RESPONSE = 'waitingArtistResp';
export const USER_CANCELLED_BEFORE_ARTIST_ACCEPT = 'userCancelledBeforeArtistAccept';
export const USER_CANCELLED_NEW_TIME = 'userCancelledNewTime';
export const USER_ACCEPTED_NEW_TIME = 'userAcceptedNewTime';
export const USER_CANCELLED_PAYMENT = 'userCancelledPayment';
export const USER_CANCELLED_AFTER_PAYMENT = 'userCancelledAfterPayment';
export const ACCEPTED_WAITING_PAYMENT = 'acceptedWaitingPayment';
export const SUGGESTED_NEW_TIME = 'suggestedNewTime';
export const ARTIST_CANCELLED_AFTER_PAYMENT = 'artistCancelledAfterPayment';
export const ARTIST_CANCELLED = 'artistCancelled';
export const USER_CANCELLED = 'userCancelled';
export const ADMIN_CANCELLED = 'adminCancelled';
export const PAID_PAYMENT = 'paidPayment';
export const PAID_AND_CONFIRMED = 'paid_and_confirmed';
export const COMPLETED = 'completed';
export const DELIVERED = 'delivered';

export const ORDERED = 'ordered';
export const CANCELLED = 'cancelled';
export const RECIEVED = 'recieved';

export const COMPLETED_AND_PAID = 'completed_and_paid';
export const COMPLETED_AND_UNPAID = 'completed_and_unpaid';
export const IN_PROGRESS = 'inProgress';
export const EXPIRED_NO_USER_RESPONSE = 'expiredNoUserResp';
export const EXPIRED_NO_PAYMENT = 'expiredNoPayment';
export const EXPIRED_NO_ARTIST_RESPONSE = 'expiredNoArtistResp';
export const PARTIALLY_REFUNDED = 'partiallyRefunded';
export const RESCHEDULE_REQUESTED = 'reschedule_requested';
export const RESCHEDULE_REQUEST_ACCEPTED = 'reschedule_request_accepted';
export const RESCHEDULE_REQUEST_REJECTED = 'reschedule_request_rejected';
export const RESCHEDULE_REQUEST_CANCELLED = 'reschedule_request_cancelled';
export const RESCHEDULE_REQUEST_CANCELLED_BY_PROVIDER = 'reschedule_request_cancelled_by_provider'; // cancelled by provider
export const SHIPPED = 'shipped';

export const RESCHEDULE_REQUEST_EXPIRED = 'reschedule_request_expired';
export const RESCHEDULE_REQUESTED_BY_PROVIDER = 'reschedule_requested_by_provider'; // requested by provider
export const RESCHEDULE_REQUEST_ACCEPTED_BY_USER = 'reschedule_request_accepted_by_user'; // accepted by user

export const NOT_AVAILABLE = 'n/a';

export const GENERAL = 'general';
export const PRIVATE = 'private';
