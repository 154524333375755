import React, { Suspense, lazy } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Dashboard from './dashboard';
import Appointments from './appointments';
import JamelahOrders from './jamelahOrders';
import Services from './service';
import AppointmentServices from './appointmentServices';
import Expenses from './expenses';

import Products from './product';
import Employees from './employee';
import Marketers from './marketers';
import Clients from './client';
import WorkingHours from './workingHours';
import Vacations from './vacations';
import offDays from './offDays';
import Pictures from './pictures';
import DiscountCodes from './discountCodes';
import Reports from './reports';
import Settings from './settings';
import Inventory from './inventory';
import withAdminLayout from '../../layout/withAdminLayout';

const Calendars = lazy(() => import('../../container/calendar/Calendar'));
const Reviews = lazy(() => import('../../container/reviews/index'));
const Sales = lazy(() => import('../../container/sales/salesContainer'));
const Messages = lazy(() => import('../../container/chat/chatContainer'));
const Notifications = lazy(() => import('../../container/notifications/index'));
const Wallet = lazy(() => import('../../container/wallet'));
const EmployeeWallet = lazy(() => import('../../container/employees/employeeWallet'));

const Admin = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={path} component={Dashboard} />
        <Route path={`${path}/appointments`} component={Appointments} />
        <Route path={`${path}/marketer`} component={Marketers} />
        <Route path={`${path}/orders`} component={JamelahOrders} />
        <Route path={`${path}/services`} component={Services} />
        <Route path={`${path}/new-services`} component={AppointmentServices} />
        <Route path={`${path}/expenses`} component={Expenses} />

        <Route path={`${path}/products`} component={Products} />
        <Route path={`${path}/employees`} component={Employees} />
        <Route path={`${path}/clients`} component={Clients} />
        <Route path={`${path}/inventory`} component={Inventory} />
        <Route path={`${path}/hours`} component={WorkingHours} />
        <Route path={`${path}/vacations`} component={Vacations} />
        <Route path={`${path}/offdays`} component={offDays} />
        <Route path={`${path}/pictures`} component={Pictures} />
        <Route path={`${path}/calendar`} component={Calendars} />
        <Route path={`${path}/codes`} component={DiscountCodes} />
        <Route path={`${path}/reviews`} component={Reviews} />
        <Route path={`${path}/reports`} component={Reports} />
        <Route path={`${path}/sales`} component={Sales} />
        <Route path={`${path}/messages`} component={Messages} />
        <Route path={`${path}/notifications`} component={Notifications} />
        <Route path={`${path}/settings`} component={Settings} />
        <Route path={`${path}/wallet`} component={Wallet} />
        <Route path={`${path}/employee/wallet`} component={EmployeeWallet} />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
