import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Container = lazy(() => import('../../container/discountCodes/index'));
const CodeDetail = lazy(() => import('../../container/discountCodes/codeDetail'));
const AddCodes = lazy(() => import('../../container/discountCodes/addCode'));
const EditCode = lazy(() => import('../../container/discountCodes/editCode'));

const AppointmentRoutes = () => {
  const { path } = useRouteMatch();
  // console.log('incomingPath',path)

  return (
    <Switch>
      <Route exact path={path} component={Container} />
      <Route path={`${path}/detail/:id`} component={CodeDetail} />
      <Route path={`${path}/edit/:id`} component={EditCode} />
      <Route path={`${path}/add`} component={AddCodes} />

    </Switch>
  );
};

export default AppointmentRoutes;
