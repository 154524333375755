import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const Gallery = lazy(() => import('../../container/pictures/index'));

const GalleryRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route  path={`${path}`} component={Gallery} />
    </Switch>
  );
};

export default GalleryRoute;
