import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Clients = lazy(() => import('../../container/clients/clientContainer'));


const ServiceRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={Clients} />
  
    </Switch>
  );
};

export default ServiceRoutes;
