import firebase from 'firebase';
import { setItem } from './utility/localStorageControl';

export const initializeFirebase = () => {
  firebase.initializeApp({
    apiKey: 'AIzaSyAfkGsn958notw_6cSZHKdFttNAKJWELgc',
    authDomain: 'portal-260905.firebaseapp.com',
    databaseURL: 'https://portal-260905.firebaseio.com',
    projectId: 'portal-260905',
    storageBucket: 'portal-260905.appspot.com',
    messagingSenderId: '442827239607',
    appId: '1:442827239607:web:0b11c436060f2f0d8f9d32',
    measurementId: 'G-N05ZSZDKHP',
  });
};

// eslint-disable-next-line consistent-return
export const askForPermissioToReceiveNotifications = async () => {
  try {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      const token = await messaging.getToken();
      setItem('fcmToken', token);
      return token;
    }
  } catch (error) {
    // console.error(error);
  }
};
