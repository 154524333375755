import React from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
// import { ReactSVG } from 'react-svg';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getTranslation } from '../translations';
import * as Constants from '../constants';

// const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu, events }) => {
  const { rtl, loggedInUserRole } = useSelector(state => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      loggedInUserRole: state.auth.userData.role,
    };
  });
  const { path } = useRouteMatch();

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <>
      {loggedInUserRole === Constants.ARTIST ? (
        <Menu
          onOpenChange={onOpenChange}
          onClick={onClick}
          mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
          theme={darkMode && 'dark'}
          // // eslint-disable-next-line no-nested-ternary
          defaultSelectedKeys={
            !topMenu
              ? [
                  `${
                    mainPathSplit.length === 1
                      ? 'home'
                      : mainPathSplit.length === 2
                      ? mainPathSplit[1]
                      : mainPathSplit[2]
                  }`,
                ]
              : []
          }
          defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
          overflowedIndicator={<FeatherIcon icon="more-vertical" />}
          openKeys={openKeys}
        >
          {!topMenu && <p className="sidebar-nav-title">{getTranslation('main_menu', rtl)}</p>}

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}`}>
                  <FeatherIcon icon="home" />
                </NavLink>
              )
            }
            key="dashboard"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}`}>
              {getTranslation('dash_board', rtl)}
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/calendar/day`}>
                  <FeatherIcon icon="calendar" />
                </NavLink>
              )
            }
            key="calendar"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/calendar/day`}>
              {getTranslation('calendar', rtl)}
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/appointments`}>
                  <FeatherIcon icon="book" />
                </NavLink>
              )
            }
            key="appointments"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/appointments`}>
              {getTranslation('appointments', rtl)}
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/sales`}>
                  <FeatherIcon icon="shopping-bag" />
                </NavLink>
              )
            }
            key="sales"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/sales`}>
              {getTranslation('sales', rtl)}
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/expenses`}>
                  <FeatherIcon icon="briefcase" />
                </NavLink>
              )
            }
            key="expenses"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/expenses`}>
              {getTranslation('expenses', rtl)}
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/new-services`}>
                  <FeatherIcon icon="scissors" />
                </NavLink>
              )
            }
            key="new-services"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/new-services`}>
              {getTranslation('your_services', rtl)}
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/products`}>
                  <FeatherIcon icon="shopping-cart" />
                </NavLink>
              )
            }
            key="products"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/products`}>
              {getTranslation('products', rtl)}
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/inventory`}>
                  <FeatherIcon icon="database" />
                </NavLink>
              )
            }
            key="inventory"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/inventory`}>
              {getTranslation('inventory', rtl)}
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/clients`}>
                  <FeatherIcon icon="user" />
                </NavLink>
              )
            }
            key="clients"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/clients`}>
              {getTranslation('clients', rtl)}
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/employees`}>
                  <FeatherIcon icon="user-plus" />
                </NavLink>
              )
            }
            key="employees"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/employees`}>
              {getTranslation('employees', rtl)}
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/vacations`}>
                  <FeatherIcon icon="sun" />
                </NavLink>
              )
            }
            key="vacations"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/vacations`}>
              {getTranslation('vacations', rtl)}
            </NavLink>
          </Menu.Item>

          {!topMenu && <p className="sidebar-nav-title">{getTranslation('jamelah_app', rtl)}</p>}

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/orders`}>
                  <FeatherIcon icon="layers" />
                </NavLink>
              )
            }
            key="jamelahorders"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/orders`}>
              {getTranslation('jamelah_orders', rtl)}
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/pictures`}>
                  <FeatherIcon icon="camera" />
                </NavLink>
              )
            }
            key="photos"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/pictures`}>
              {getTranslation('photos', rtl)}
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/services`}>
                  <FeatherIcon icon="scissors" />
                </NavLink>
              )
            }
            key="services"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/services`}>
              {getTranslation('app_services', rtl)}
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/hours`}>
                  <FeatherIcon icon="watch" />
                </NavLink>
              )
            }
            key="workinghours"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/hours`}>
              {getTranslation('working_hours', rtl)}
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/offdays`}>
                  <FeatherIcon icon="shield-off" />
                </NavLink>
              )
            }
            key="to-do"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/offdays`}>
              {getTranslation('off_days', rtl)}
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/wallet`}>
                  <FeatherIcon icon="credit-card" />
                </NavLink>
              )
            }
            key="wallet"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/wallet`}>
              {getTranslation('wallet', rtl)}
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/codes`}>
                  <FeatherIcon icon="gift" />
                </NavLink>
              )
            }
            key="starter"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/codes`}>
              {getTranslation('discount_codes', rtl)}
            </NavLink>
          </Menu.Item>

          <Menu.Item icon={!topMenu && <FeatherIcon icon="star" />} key="maintenance">
            <NavLink onClick={toggleCollapsed} to={`${path}/reviews`}>
              {getTranslation('reviews', rtl)}
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/reports`}>
                  <FeatherIcon icon="file-text" />
                </NavLink>
              )
            }
            key="reports"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/reports`}>
              {getTranslation('reports', rtl)}
            </NavLink>
          </Menu.Item>

          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}/settings`}>
                  <FeatherIcon icon="settings" />
                </NavLink>
              )
            }
            key="settings"
          >
            <NavLink onClick={toggleCollapsed} to={`${path}/settings`}>
              {getTranslation('settings', rtl)}
            </NavLink>
          </Menu.Item>
        </Menu>
      ) : (
        <>
          {loggedInUserRole === Constants.CO_ORDINATOR ? (
            <Menu
              onOpenChange={onOpenChange}
              onClick={onClick}
              mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
              theme={darkMode && 'dark'}
              // // eslint-disable-next-line no-nested-ternary
              defaultSelectedKeys={
                !topMenu
                  ? [
                      `${
                        mainPathSplit.length === 1
                          ? 'home'
                          : mainPathSplit.length === 2
                          ? mainPathSplit[1]
                          : mainPathSplit[2]
                      }`,
                    ]
                  : []
              }
              defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
              overflowedIndicator={<FeatherIcon icon="more-vertical" />}
              openKeys={openKeys}
            >
              {!topMenu && <p className="sidebar-nav-title">{getTranslation('main_menu', rtl)}</p>}

              <Menu.Item
                icon={
                  !topMenu && (
                    <NavLink className="menuItem-iocn" to={`${path}/calendar/day`}>
                      <FeatherIcon icon="calendar" />
                    </NavLink>
                  )
                }
                key="calendar"
              >
                <NavLink onClick={toggleCollapsed} to={`${path}/calendar/day`}>
                  {getTranslation('calendar', rtl)}
                </NavLink>
              </Menu.Item>

              <Menu.Item
                icon={
                  !topMenu && (
                    <NavLink className="menuItem-iocn" to={`${path}/appointments`}>
                      <FeatherIcon icon="book" />
                    </NavLink>
                  )
                }
                key="appointments"
              >
                <NavLink onClick={toggleCollapsed} to={`${path}/appointments`}>
                  {getTranslation('appointments', rtl)}
                </NavLink>
              </Menu.Item>

              {/* <Menu.Item
  icon={
    !topMenu && (
      <NavLink className="menuItem-iocn" to={`${path}/sales`}>
        <FeatherIcon icon="shopping-bag" />
      </NavLink>
    )
  }
  key="sales"
>
  <NavLink onClick={toggleCollapsed} to={`${path}/sales`}>
  {getTranslation('sales', rtl)}
  </NavLink>
</Menu.Item> */}

              <Menu.Item
                icon={
                  !topMenu && (
                    <NavLink className="menuItem-iocn" to={`${path}/services`}>
                      <FeatherIcon icon="scissors" />
                    </NavLink>
                  )
                }
                key="services"
              >
                <NavLink onClick={toggleCollapsed} to={`${path}/services`}>
                  {getTranslation('services', rtl)}
                </NavLink>
              </Menu.Item>

              <Menu.Item
                icon={
                  !topMenu && (
                    <NavLink className="menuItem-iocn" to={`${path}/products`}>
                      <FeatherIcon icon="shopping-cart" />
                    </NavLink>
                  )
                }
                key="products"
              >
                <NavLink onClick={toggleCollapsed} to={`${path}/products`}>
                  {getTranslation('products', rtl)}
                </NavLink>
              </Menu.Item>

              <Menu.Item
                icon={
                  !topMenu && (
                    <NavLink className="menuItem-iocn" to={`${path}/clients`}>
                      <FeatherIcon icon="user" />
                    </NavLink>
                  )
                }
                key="clients"
              >
                <NavLink onClick={toggleCollapsed} to={`${path}/clients`}>
                  {getTranslation('clients', rtl)}
                </NavLink>
              </Menu.Item>

              {!topMenu && <p className="sidebar-nav-title">{getTranslation('jamelah_app', rtl)}</p>}

              <Menu.Item
                icon={
                  !topMenu && (
                    <NavLink className="menuItem-iocn" to={`${path}/orders`}>
                      <FeatherIcon icon="layers" />
                    </NavLink>
                  )
                }
                key="jamelahorders"
              >
                <NavLink onClick={toggleCollapsed} to={`${path}/orders`}>
                  {getTranslation('jamelah_orders', rtl)}
                </NavLink>
              </Menu.Item>

              <Menu.Item
                icon={
                  !topMenu && (
                    <NavLink className="menuItem-iocn" to={`${path}/hours`}>
                      <FeatherIcon icon="watch" />
                    </NavLink>
                  )
                }
                key="workinghours"
              >
                <NavLink onClick={toggleCollapsed} to={`${path}/hours`}>
                  {getTranslation('working_hours', rtl)}
                </NavLink>
              </Menu.Item>

              <Menu.Item
                icon={
                  !topMenu && (
                    <NavLink className="menuItem-iocn" to={`${path}/employee/wallet`}>
                      <FeatherIcon icon="credit-card" />
                    </NavLink>
                  )
                }
                key="wallet"
              >
                <NavLink onClick={toggleCollapsed} to={`${path}/employee/wallet`}>
                  {getTranslation('wallet', rtl)}
                </NavLink>
              </Menu.Item>

              <Menu.Item icon={!topMenu && <FeatherIcon icon="airplay" />} key="maintenance">
                <NavLink onClick={toggleCollapsed} to={`${path}/reviews`}>
                  {getTranslation('reviews', rtl)}
                </NavLink>
              </Menu.Item>

              <Menu.Item
                icon={
                  !topMenu && (
                    <NavLink className="menuItem-iocn" to={`${path}/reports`}>
                      <FeatherIcon icon="info" />
                    </NavLink>
                  )
                }
                key="reports"
              >
                <NavLink onClick={toggleCollapsed} to={`${path}/reports`}>
                  {getTranslation('reports', rtl)}
                </NavLink>
              </Menu.Item>
            </Menu>
          ) : (
            //employees(driver , artist)

            <>
              {loggedInUserRole === Constants.MARKETER ? (
                <Menu
                  onOpenChange={onOpenChange}
                  onClick={onClick}
                  mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
                  theme={darkMode && 'dark'}
                  // // eslint-disable-next-line no-nested-ternary
                  defaultSelectedKeys={
                    !topMenu
                      ? [
                          `${
                            mainPathSplit.length === 1
                              ? 'home'
                              : mainPathSplit.length === 2
                              ? mainPathSplit[1]
                              : mainPathSplit[2]
                          }`,
                        ]
                      : []
                  }
                  defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
                  overflowedIndicator={<FeatherIcon icon="more-vertical" />}
                  openKeys={openKeys}
                >
                  {!topMenu && <p className="sidebar-nav-title">{getTranslation('main_menu', rtl)}</p>}

                  <Menu.Item
                    icon={
                      !topMenu && (
                        <NavLink className="menuItem-iocn" to={`${path}/calendar/day`}>
                          <FeatherIcon icon="home" />
                        </NavLink>
                      )
                    }
                    key="dashboard"
                  >
                    <NavLink onClick={toggleCollapsed} to={`${path}/marketer`}>
                      {getTranslation('dash_board', rtl)}
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    icon={
                      !topMenu && (
                        <NavLink className="menuItem-iocn" to={`${path}/marketer/orders`}>
                          <FeatherIcon icon="book" />
                        </NavLink>
                      )
                    }
                    key="orders"
                  >
                    <NavLink onClick={toggleCollapsed} to={`${path}/marketer/orders`}>
                      {getTranslation('orders', rtl)}
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    icon={
                      !topMenu && (
                        <NavLink className="menuItem-iocn" to={`${path}/hours`}>
                          <FeatherIcon icon="gift" />
                        </NavLink>
                      )
                    }
                    key="codes"
                  >
                    <NavLink onClick={toggleCollapsed} to={`${path}/marketer/codes`}>
                      {getTranslation('codes', rtl)}
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    icon={
                      !topMenu && (
                        <NavLink className="menuItem-iocn" to={`${path}/marketer/providers`}>
                          <FeatherIcon icon="credit-card" />
                        </NavLink>
                      )
                    }
                    key="artists"
                  >
                    <NavLink onClick={toggleCollapsed} to={`${path}/marketer/providers`}>
                      {getTranslation('service_providers', rtl)}
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    icon={
                      !topMenu && (
                        <NavLink className="menuItem-iocn" to={`${path}/marketer/wallet`}>
                          <FeatherIcon icon="credit-card" />
                        </NavLink>
                      )
                    }
                    key="wallet"
                  >
                    <NavLink onClick={toggleCollapsed} to={`${path}/marketer/wallet`}>
                      {getTranslation('wallet', rtl)}
                    </NavLink>
                  </Menu.Item>
                </Menu>
              ) : (
                <Menu
                  onOpenChange={onOpenChange}
                  onClick={onClick}
                  mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
                  theme={darkMode && 'dark'}
                  // // eslint-disable-next-line no-nested-ternary
                  defaultSelectedKeys={
                    !topMenu
                      ? [
                          `${
                            mainPathSplit.length === 1
                              ? 'home'
                              : mainPathSplit.length === 2
                              ? mainPathSplit[1]
                              : mainPathSplit[2]
                          }`,
                        ]
                      : []
                  }
                  defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
                  overflowedIndicator={<FeatherIcon icon="more-vertical" />}
                  openKeys={openKeys}
                >
                  {!topMenu && <p className="sidebar-nav-title">{getTranslation('main_menu', rtl)}</p>}

                  <Menu.Item
                    icon={
                      !topMenu && (
                        <NavLink className="menuItem-iocn" to={`${path}/calendar/day`}>
                          <FeatherIcon icon="calendar" />
                        </NavLink>
                      )
                    }
                    key="calendar"
                  >
                    <NavLink onClick={toggleCollapsed} to={`${path}/calendar/day`}>
                      {getTranslation('calendar', rtl)}
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    icon={
                      !topMenu && (
                        <NavLink className="menuItem-iocn" to={`${path}/appointments`}>
                          <FeatherIcon icon="book" />
                        </NavLink>
                      )
                    }
                    key="appointments"
                  >
                    <NavLink onClick={toggleCollapsed} to={`${path}/appointments`}>
                      {loggedInUserRole === Constants.ARTIST_EMPLOYEE
                        ? getTranslation('appointments', rtl)
                        : getTranslation('orders', rtl)}
                    </NavLink>
                  </Menu.Item>

                  {!topMenu && <p className="sidebar-nav-title">{getTranslation('jamelah_app', rtl)}</p>}

                  <Menu.Item
                    icon={
                      !topMenu && (
                        <NavLink className="menuItem-iocn" to={`${path}/hours`}>
                          <FeatherIcon icon="watch" />
                        </NavLink>
                      )
                    }
                    key="workinghours"
                  >
                    <NavLink onClick={toggleCollapsed} to={`${path}/hours`}>
                      {getTranslation('working_hours', rtl)}
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    icon={
                      !topMenu && (
                        <NavLink className="menuItem-iocn" to={`${path}/employee/wallet`}>
                          <FeatherIcon icon="credit-card" />
                        </NavLink>
                      )
                    }
                    key="wallet"
                  >
                    <NavLink onClick={toggleCollapsed} to={`${path}/employee/wallet`}>
                      {getTranslation('wallet', rtl)}
                    </NavLink>
                  </Menu.Item>
                </Menu>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  events: propTypes.object,
};

export default MenuItems;
