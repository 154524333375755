import { theme, darkTheme } from './theme/themeVariables';
import * as Constants from '../constants';

const config = {
  darkMode: false,
  language: Constants.ENGLISH,
  topMenu: false,
  rtl: true,
  theme,
  darkTheme,
  API_LOCAL_URL: 'http://localhost:7000/api/v1/',
  API_KEY: 'fHUuioTyELUantbGA7O4qP7NkY6JMdE8',

  API_DEV_URL: 'http://ec2-107-23-178-5.compute-1.amazonaws.com:7000/api/v1/',

  API_PROD_URL: 'https://napi.jamelah.app/api/v1/',

  REACT_APP_BACKEND_API_URL: 'https://napi.jamelah.app/api/v1/',
};

export default config;
