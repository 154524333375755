/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Redirect, Route, NavLink } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import firebase from 'firebase';
import Chat from '@twilio/conversations';
import store from './redux/store';
import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/css/style.css';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import { initializeFirebase } from './push-notification';
import { Modal } from './components/modals/antd-modals';
import { Button } from './components/buttons/buttons';
import { getTranslation } from './translations';

import * as Constants from './constants';
import { DataService } from './config/dataService/dataService';
import invoice from './routes/invoice';

const { theme } = config;

const ProviderConfig = () => {
  const { rtl, isLoggedIn, loggedInUserId, topMenu, darkMode, loggedInUserData } = useSelector(state => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login,
      loggedInUserData: state.auth.userData,
      loggedInUserId: state.auth.login ? state.auth.userData.userId : '',
    };
  });

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  const [state, setState] = useState({
    visible: false,
    modalType: 'primary',
    colorModal: false,
    notificationTitle: '',
    showMessageNotification: false,
    messageData: '',
    notificationBody: '',
    referenceId: '',
  });
  useEffect(() => {
    initializeFirebase();
    // eslint-disable-next-line no-use-before-define
    isLoggedIn && getTokenAndSetupClient();

    if (firebase.messaging.isSupported()) {
      firebase.messaging().onMessage(payload => {
        setState({
          ...state,
          visible: true,
          referenceId: payload.data.referenceId,
          notificationTitle: payload.notification.title,
          notificationBody: payload.notification.body,
        });
      });
    }
    // eslint-disable-next-line
  }, []);

  const getTokenAndSetupClient = () => {
    const endpoint = `chat/token?identity=${loggedInUserId}&API_KEY=fHUuioTyELUantbGA7O4qP7NkY6JMdE8&web=true`;

    DataService.get(endpoint)
      .then(result => {
        // create chat client
        Chat.create(result.data.token)
          // eslint-disable-next-line no-use-before-define
          .then(setupChatClient)
          .catch
          // handleError
          ();
      })
      .catch(error => {
        setState({
          isLoading: false,
          error,
        });
      });
  };

  const setupChatClient = client => {
    try {
      // eslint-disable-next-line no-use-before-define
      client.on('messageAdded', messageAddedToClient);
    } catch (e) {
      // this.handleError(e);
    }
  };

  const messageAddedToClient = message => {
    if (message.state.author !== loggedInUserId) {
      if (message.state.body === 'Image') {
        setState({
          messageData: 'Image',
          showMessageNotification: true,
        });
      } else if (message.state.body === 'Location') {
        setState({
          messageData: 'Location',
          showMessageNotification: true,
        });
      } else {
        setState({
          messageData: message.state.body,
          showMessageNotification: true,
        });
      }
    }
    //   (message.state.attributes.messageContent)
  };

  const handleOk = () => {
    setState({
      visible: false,
      colorModal: false,
    });
  };

  const visitOrderDetail = () => {
    setState({
      ...state,
      showMessageNotification: false,
      visible: false,
    });
  };

  const handleCancel = () => {
    setState({
      visible: false,
      colorModal: false,
      showMessageNotification: false,
    });
  };

  const getRedirectionRoute = role => {
    let route = '';
    if (role === Constants.ARTIST) {
      route = '/admin';
    } else if (role === Constants.MARKETER) {
      route = '/admin/marketer';
    } else {
      route = '/admin/calendar/day';
    }
    return route;
  };

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router basename={process.env.PUBLIC_URL}>
          <Modal
            type={state.modalType}
            title={getTranslation('jamelah_order', rtl)}
            visible={state.visible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <NavLink
                onClick={visitOrderDetail}
                to={{
                  pathname: `/admin/orders/order-detail/${state.referenceId}`,
                }}
              >
                <Button type="primary" key="submit" style={{ margin: '0px 6px' }}>
                  {getTranslation('check_now', rtl)}
                </Button>
              </NavLink>,
            ]}
          >
            <h2>{state.notificationTitle}</h2>
            <p>{state.notificationBody}</p>
          </Modal>

          <Modal
            type={state.modalType}
            title={getTranslation('new_message_recieved', rtl)}
            visible={state.showMessageNotification}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <NavLink
                onClick={visitOrderDetail}
                to={{
                  pathname: `/admin/messages`,
                }}
              >
                <Button type="primary" key="submit" style={{ margin: '0px 6px' }}>
                  {getTranslation('go_to_messages', rtl)}
                </Button>
              </NavLink>,
            ]}
          >
            <h2>{state.notificationTitle}</h2>
            <p>{state.messageData}</p>
          </Modal>
          {!isLoggedIn ? (
            path.split('invoice/').length === 2 ? (
              <Route path="/invoice" component={invoice} />
            ) : (
              <Route path="/" component={Auth} />
            )
          ) : path.split('invoice/').length === 2 ? (
            <ProtectedRoute path="/invoice" component={invoice} />
          ) : (
            <ProtectedRoute path="/admin" component={Admin} />
          )}
          {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
            <Redirect to={getRedirectionRoute(loggedInUserData.role)} />
          )}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);
