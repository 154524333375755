import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Reports = lazy(() => import('../../container/reports/index'));
const Orders = lazy(() => import('../../container/reports/ordersReport'));
const AddService = lazy(() => import('../../container/services/addService'));
const EditService = lazy(() => import('../../container/services/editService'));

const ServiceRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={Reports} />
      <Route path={`${path}/orders/:id`} component={Orders} />
      <Route path={`${path}/add`} component={AddService} />
      <Route path={`${path}/edit`} component={EditService} />
    </Switch>
  );
};

export default ServiceRoutes;
