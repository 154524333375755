import React, { useEffect, useState } from 'react';
import { Badge } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { AtbdTopDropdwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';
import { DataService } from '../../../config/dataService/dataService';
import * as Constants from '../../../constants';
import { getTranslation } from '../../../translations';

const NotificationBox = () => {
  const { rtl, loggedInUserId } = useSelector(state => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      loggedInUserId: state.auth.userData.userId,
      loggedInUserImage: state.auth.userData.profilePicture,
      artistMobileNo: state.auth.userData.userNumber,
    };
  });
  const [state, setState] = useState({
    data: [],
  });

  const fetchNotifications = () => {
    const endpoint = `notification?${Constants.API_KEY}&id=${loggedInUserId}&limit=${5}&pageNo=${1}`;
    // console.log('fetchApiResponse:endpoint ', endpoint, this.state.activeRequests);

    DataService.get(endpoint)
      .then(result => {
        // console.log('notifications', result.data);
        setState({
          data: result.data.notifications,
        });
      })
      // eslint-disable-next-line no-unused-vars
      .catch(error => {
        setState({
          isLoading: false,
          data: [],
        });
        // console.log('error', error);
      });
  };
  useEffect(() => {
    fetchNotifications();
    // eslint-disable-next-line
  }, []);
  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} props={props} />;
  };

  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      [rtl ? 'left' : 'right']: '2px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div className="hello" style={thumbStyle} />;
  };

  const getPath = (requestType, referenceId) => {
    let path = '#';
    if (requestType === 'request') {
      path = `/admin/orders/order-detail/${referenceId}`;
    } else if (requestType === 'service') {
      path = `/admin/services`;
    } else if (requestType === 'coupon') {
      path = `/admin/codes`;
    }
    return path;
  };

  const renderView = ({ style, ...props }) => {
    const customStyle = {
      marginRight: rtl && 'auto',
      [rtl ? 'marginLeft' : 'marginRight']: '-17px',
    };
    return <div {...props} style={{ ...style, ...customStyle }} />;
  };

  renderThumb.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  renderView.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  const content = (
    <AtbdTopDropdwon className="atbd-top-dropdwon">
      <Heading as="h5" className="atbd-top-dropdwon__title">
        <span className="title-text">{getTranslation('notifications', rtl)}</span>
        {/* <Badge className="badge-success" count={3} /> */}
      </Heading>
      <Scrollbars
        autoHeight
        autoHide
        renderThumbVertical={renderThumb}
        renderView={renderView}
        renderTrackVertical={renderTrackVertical}
      >
        <ul className="atbd-top-dropdwon__nav notification-list">
          {state.data.map(notification => (
            <li>
              {getPath(notification.notificationType, notification.referenceId) !== '#' ? (
                <Link
                  to={{
                    pathname: getPath(notification.notificationType, notification.referenceId),
                  }}
                >
                  <div className="atbd-top-dropdwon__content notifications">
                    {/* <div className="bg-primary">
                  <img className="notification-icon" src={loggedInUserImage}/>
                </div> */}
                    <div className="notification-content d-flex">
                      <div className="notification-text">
                        <Heading as="h5">
                          <span>{notification.title}</span>
                        </Heading>
                        <p>{rtl ? notification.createdAt.ar : notification.createdAt.en}</p>
                        <p>{notification.body}</p>
                      </div>
                      {/* <div className="notification-status">
                    <Badge dot />
                  </div> */}
                    </div>
                  </div>
                </Link>
              ) : (
                <Link to="#">
                  <div className="atbd-top-dropdwon__content notifications">
                    {/* <div className="bg-primary">
                        <img className="notification-icon" src={loggedInUserImage}/>
                      </div> */}
                    <div className="notification-content d-flex">
                      <div className="notification-text">
                        <Heading as="h5">
                          <span>{notification.title}</span>
                        </Heading>
                        <p>{rtl ? notification.createdAt.ar : notification.createdAt.en}</p>
                        <p>{notification.body}</p>
                      </div>
                      {/* <div className="notification-status">
                          <Badge dot />
                        </div> */}
                    </div>
                  </div>
                </Link>
              )}
            </li>
          ))}
        </ul>
      </Scrollbars>
      <Link className="btn-seeAll" to="/admin/notifications">
        {getTranslation('see_all_notifications', rtl)}
      </Link>
    </AtbdTopDropdwon>
  );

  return (
    <div className="notification">
      <Popover placement="bottomLeft" content={content} action="click">
        <Badge dot offset={[-8, -5]}>
          <Link to="#" className="head-example">
            <FeatherIcon icon="bell" size={20} />
          </Link>
        </Badge>
      </Popover>
    </div>
  );
};

export default NotificationBox;
