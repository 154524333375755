import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Appointments = lazy(() => import('../../container/appointments'));
const OrderDetail = lazy(() => import('../../container/appointments/orderDetail'));
const AddAppointment = lazy(() => import('../../container/appointments/addAppointment'));
const EditAppointment = lazy(() => import('../../container/appointments/editAppointment'));
const RefundAppointment = lazy(() => import('../../container/appointments/refundAppointment'));
const AppointmentRoutes = () => {
  const { path } = useRouteMatch();
  // console.log('incomingPath',path)

  return (
    <Switch>
      <Route exact path={path} component={Appointments} />
      <Route path={`${path}/order-detail/:id`} component={OrderDetail} />
      <Route path={`${path}/edit/:id`} component={EditAppointment} />
      <Route path={`${path}/refund/:id`} component={RefundAppointment} />
      <Route path={`${path}/add`} component={AddAppointment} />

    </Switch>
  );
};

export default AppointmentRoutes;
